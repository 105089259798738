<template>
    <section class="section">
        <h2>出品禁止メーカー設定</h2>

        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <div v-for="forbidden_maker,index of forbidden_makers" :key="forbidden_maker.forbidden_maker_id" class="row mb-2">
                <div class="col-12">
                    <input type="text" class="form-control" v-model="forbidden_maker.maker_name">
                </div>
                <div class="col-4">
                    <button class="btn btn-outline-danger" @click="removeRow(index)">削除</button>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12"></div>
                <div class="col-4">
                    <button class="btn btn-secondary" @click="addRow()">行追加</button>
                </div>
            </div>
            <div>
                <button class="btn btn-primary" @click="save()">保存</button>
            </div>
        </template>
    </section>
</template>

<script>
import InlineLoader from '@/shared/components/tool/InlineLoader';

export default {
    name: 'PageSettingForbiddenMaker',
    components: {
        InlineLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: false,
            forbidden_makers: [],
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loading = true;
            this.$http.get('/forbidden-makers')
            .then((response) => {
                this.forbidden_makers = response.data;
            })
            .finally(() => {
                this.loading = false;
            });
        },
        save() {
            let maker_names = this.forbidden_makers
                .map(row => row.maker_name)
                .filter(name => name.length > 0);
            this.startScreenLoading();
            this.$http.post('/forbidden-makers/bulk-update', {maker_names})
            .then((response) => {
                this.forbidden_makers = response.data;
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        addRow() {
            this.forbidden_makers.push({maker_name: null});
        },
        removeRow(index) {
            this.forbidden_makers.splice(index, 1);
        },
    }
}
</script>

<style scoped>

</style>
